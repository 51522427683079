<template>
  <ftx-dialog
    :dialog="dialog"
    position="standard"
    class="feedback-dialog"
    persistent
  >
    <template v-slot:DialogContent>
      <q-card class="writeFeedback q-py-md" rounded>
        <q-form @submit="submitReview">
          <q-card-section class="text-center">
            <img
              v-if="theme?.logoURL"
              :style="
                !isMobile
                  ? 'max-width:200px; width:auto; max-height:60px;'
                  : ' max-width:200px; width:auto; max-height:50px;'
              "
              :src="theme.logoURL"
              class="inline-block vertical-middle"
            />
          </q-card-section>
          <q-card-section class="text-center text-h6">
            Hello
            <strong class="" v-if="isLoggedIn">
              {{ currentUser.firstName }}
            </strong>
            <strong class="" v-else-if="guestUser">
              {{ guestUser.firstName }} </strong
            >,<br />
            Please take a moment to tell us about your experience using our
            site!
          </q-card-section>
          <q-card-section class="text-center q-py-none">
            <q-item-label class="review-detail-ratings">
              <!-- <q-rating
                v-model="reviews.rating"
                icon="star_border"
                icon-selected="star"
                :max="5"
                no-dimming
                class="review-dialog-rating"
                @input="
                  (val) => {
                    reviews.rating = val
                  }
                "
              /> -->
              <thumb-rating :onRating="onRating" />
            </q-item-label>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <div class="q-pa-md">
              <div class="row q-col-gutter-md">
                <div class="col-12 feedback-description">
                  <q-input
                    outlined
                    bottom-slots
                    v-model.trim="reviews.description"
                    type="textarea"
                    placeholder="Description"
                    rows="10"
                    maxlength="500"
                  />
                  <span
                    class="float-right text-caption"
                    v-text="`${reviews.description.length}/500`"
                  />
                </div>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="text-center q-pt-none">
            <q-btn
              label="Skip"
              unelevated
              outline
              rounded
              class="q-mr-md q-pa-sm q-pl-lg q-pr-lg"
              v-close-popup
            />
            <q-btn
              type="submit"
              label="Submit"
              color="primary"
              rounded
              class="q-pa-sm q-pl-lg q-pr-lg"
              unelevated
              :loading="submitting"
            >
              <q-tooltip
                max-width="180px"
                anchor="top middle"
                self="bottom middle"
                :offset="[10, 10]"
                content-class="bg-dark"
                v-if="reviews.rating <= 0"
                ref="submitToolTip"
              >
                Please Select Rating.
              </q-tooltip>
            </q-btn>
          </q-card-section>
        </q-form>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ThumbRating from 'components/common/ThumbRating'

export default {
  name: 'WriteFeedback',
  components: {
    ThumbRating,
  },
  data() {
    return {
      dialog: 'WriteFeedback',
      reviews: {
        orderID: null,
        description: '',
        rating: 0,
      },
      submitting: false,
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('guest', ['guestUser']),
    properties() {
      return this.getDialogProperties('WriteFeedback')
    },
  },
  methods: {
    onRating: function (val) {
      this.reviews.rating = val
    },
    submitReview: function () {
      this.reviews = { ...this.reviews, ...this.properties }
      if (!this.reviews.rating && this.$refs.submitToolTip) {
        this.$refs.submitToolTip.show()
        return
      }
      this.submitting = true
      this.$store
        .dispatch('customerReview/customerReviewBetaFeedback', this.reviews)
        .then((response) => {
          if (response && response.data) {
            if (this.isLoggedIn)
              this.$store.commit(
                'customer/SET_CUSTOMER',
                Object.assign(
                  {},
                  { ...this.customer },
                  { isCustomerReviewSubmitted: true }
                )
              )
            this.changeDialogState({
              dialog: 'WriteFeedback',
              val: false,
            })
            this.showSuccess('Feedback submitted successfully.')
            if (!this.isLoggedIn)
              this.$store.dispatch('guest/resetGuestUserInfo')
          }
        })
        .catch((err) => {
          console.log(err)
          this.showError(err.message)
        })
        .finally(() => (this.submitting = false))
    },
  },
}
</script>

<style lang="scss">
.q-dialog__inner--maximized > div.writeFeedback {
  max-width: 500px;
  min-width: 500px;
  width: 100%;
  height: auto;
  border-radius: 10px !important;
  @media (max-width: 1023px) {
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
  }
}
.writeFeedback {
  .review-dialog-rating {
    padding: 20px 0;
    color: #707070;
    font-size: 2em;
    .q-rating__icon {
      text-shadow: none;
    }
    .q-rating__icon--active {
      color: #ffbd09;
    }
    @media (min-width: 1681px) {
      font-size: 2.5em;
    }
  }
  textarea {
    resize: none !important;
  }
  .feedback-description > .q-field--with-bottom {
    padding-bottom: unset !important;
  }
}
</style>
