<template>
  <div id="outside">
    <div class="rating">
      <!-- Thumbs up -->
      <div class="like grow" @click="onRating(5)">
        <SvgIcon icon="thumb-likef" class="like" size="3em" />
        <legend>Good</legend>
      </div>
      <!-- Thumbs down -->
      <div class="dislike grow" @click="onRating(1)">
        <SvgIcon icon="thumb-dislikef" class="like" size="3em" />
        <legend>Bad</legend>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmilyRating',
  props: {
    onRating: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    let elements = document.querySelectorAll('.like, .dislike')
    elements.forEach((element) => {
      element.addEventListener('click', function (event) {
        event.preventDefault()
        let activeElement = document.querySelector('.active')
        if (activeElement) activeElement.classList.remove('active')
        this.classList.add('active')
      })
    })
  },
}
</script>

<style lang="scss" scoped>
body {
  font-family: 'Anaheim';
  font-size: 1.2em;
}

#outside {
  //   background-color: lightgoldenrodyellow;
  padding-top: 25px;
  padding-bottom: 25px;
}

h1 {
  font-size: 1.5em;
  text-align: center;
  text-transform: capitalize;
}

form {
  /* Just to center the form on the page */
  margin: 0 auto;
  width: 70%;
  /* To see the limits of the form */
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 1em;
}
#survey-form {
  background-color: white;
}

fieldset {
  border: 1px solid lightgray;
  margin: 10px;
}

legend {
  font-weight: 700;
}

#number {
  width: 150px;
}

div + div {
  margin-top: 1em;
}

input,
textarea {
  /* To make sure that all text field have the same font settings
     By default, textarea are set with a monospace font */
  /* font: 1em sans-serif; */

  /* To give the same size to all text field */
  width: 200px;

  /*   -moz-box-sizing: border-box;
       box-sizing: border-box; */

  /* To harmonize the look & feel of text field border */
  border: 1px solid #999;
}

input:focus,
textarea:focus {
  /* To give a little highligh on active elements */
  border-color: OrangeRed;
}

textarea {
  /* To properly align multiline text field with their label */
  vertical-align: top;

  /* To give enough room to type some text */
  height: 5em;

  /* To allow users to resize any textarea vertically
     It works only on Chrome, Firefox and Safari */
  resize: vertical;
}

.button {
  /* To position the buttons to the same position of the text fields */
  padding-left: 90px; /* same size as the label elements */
}

button {
  margin: 0.5em;
  font-size: 1em;
  text-transform: capitalize;
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 2px;
}

#submitbutton {
  display: flex;
  justify-content: center;
}
.checked {
  color: orange;
}
* {
  margin: 0;
  padding: 0;
}
.rate {
  float: left;
  height: 46px;
  padding: 0 100px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.rating {
  display: inline-block;
  width: 100%;
  margin-top: -40px;
  //   margin-left: -120px;
  padding-top: 40px;
  text-align: center;
}

.like,
.dislike {
  display: inline-block;
  cursor: pointer;
  margin: 10px;
}
.like legend {
  margin-left: 10px;
}
.dislike legend {
  margin-left: 15px;
}
.like:hover,
.like:active {
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}
.dislike:hover,
.dislike:active {
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}
.like.active {
  color: #008000;
}
.dislike.active {
  color: #ff0000;
}
</style>
